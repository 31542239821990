import { useStoreMap, useEvent } from 'effector-react';
import * as toggler from '@/store/togglerMap';
import { hasPageScrollbar } from '@/utils/dom';

import { useCallback } from 'react';

const useModal = (id) => {
  const togglerOn = useEvent(toggler.on);
  const togglerOff = useEvent(toggler.off);
  const togglerSet = useEvent(toggler.set);
  const togglerToggle = useEvent(toggler.toggle);

  const isOpen = useStoreMap({
    store: toggler.$togglerMap,
    keys: [id],
    fn: (state, [currentId]) => state[currentId] || false,
  });

  const open = useCallback(() => {
    if (hasPageScrollbar()) {
      document.documentElement.classList.add('is-active-padding-fix');
    }
    togglerOn(id);
  }, [id]);

  const toggle = useCallback(() => {
    if (hasPageScrollbar() && isOpen) {
      document.documentElement.classList.add('is-active-padding-fix');
    }
    togglerToggle(id);
  }, [id, isOpen]);

  const close = useCallback(() => togglerOff(id), [id]);
  const set = useCallback((newValue) => togglerSet({ id, value: newValue }), [
    id,
  ]);

  return {
    isOpen,
    open,
    close,
    toggle,
    set,
  };
};

export default useModal;
