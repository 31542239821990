import rootDomain from '@/store/rootDomain';
import omit from 'lodash.omit';

const on = rootDomain.createEvent();
const off = rootDomain.createEvent();
const toggle = rootDomain.createEvent();
const set = rootDomain.createEvent();

const $togglerMap = rootDomain
  .createStore({})
  .on(on, (state, id) => ({ ...state, [id]: true }))
  .on(off, (state, id) => omit(state, id))
  .on(set, (state, { id, value }) => ({ ...state, [id]: value }))
  .on(toggle, (state, id) => ({ ...state, [id]: !state[id] }));

export { on, off, toggle, set, $togglerMap };
