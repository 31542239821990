import React, { useContext } from 'react';
import cx from 'clsx';
import { motion } from 'framer-motion';
import NavbarWrapper from '@/components/Navbar/Navbar';
import Drawer from '@/components/Drawer/Drawer';
import { Button } from '@/components/inputs';
import Burger from '@/components/Burger/Burger';
import ScrollSpyMenu from '@/components/ScrollSpyMenu/ScrollSpyMenu';
import useModal from '@/hooks/useModal';
import { modals } from '@/constants/ctx';

import CloseIcon from '@/assets/img/icons/close.inline.svg';
import { DrawerContext } from '@/contexts/DrawerContext';
// import PropTypes from 'prop-types';

import * as styles from './TopNavbar.module.scss';
import data from './data';

const TopNavbar = ({ isSticky, hasMenu = true }) => {
  const { state, dispatch } = useContext(DrawerContext);

  const { open: openOrderModal } = useModal(modals.orderModal);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  const motionVariants = {
    hidden: {
      opacity: 0,
      y: -20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
      },
    },
  };

  return (
    <NavbarWrapper
      className={cx('c-compensate-for-scrollbar', styles.topNavbar)}
    >
      <motion.div
        className={cx('container', styles.topNavbarContainer)}
        variants={motionVariants}
        initial="hidden"
        animate="visible"
      >
        <img
          className={styles.logoImg}
          src={data.logoSrc}
          alt={data.logoAlt}
          width="300"
          height="68"
        />
        {hasMenu && (
          <div className={styles.desktopMenu}>
            <ScrollSpyMenu
              currentClassName={styles.isMenuItemActive}
              menuItems={data.menuItems}
              offset={-100}
              scrollLine
            />
          </div>
        )}
        <Button
          theme={isSticky ? 'primary' : 'secondary'}
          className={styles.actionBtn}
          onClick={openOrderModal}
        >
          {data.orderBtnText}
        </Button>
        {hasMenu && (
          <Drawer
            width="280px"
            placement="right"
            drawerHandler={<Burger className={styles.mobileMenuToggler} />}
            closeButton={<CloseIcon />}
            open={state.isOpen}
            toggleHandler={toggleHandler}
          >
            <ScrollSpyMenu
              className={styles.mobileMenu}
              menuItems={data.menuItems}
              currentClassName={styles.isMenuItemActive}
              isDrawerToggable={true}
              offset={-100}
            />
          </Drawer>
        )}
      </motion.div>
    </NavbarWrapper>
  );
};

TopNavbar.propTypes = {};

TopNavbar.defaultProps = {};

export default TopNavbar;
