import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import * as styles from './Burger.module.scss';

const Burger = ({ theme, customColor, className, ...props }) => {
  return (
    <div
      className={cx(
        styles.burger,
        theme && styles[`burger--${theme}`],
        className
      )}
      style={customColor && { color: customColor }}
      {...props}
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

Burger.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(['primary', 'secondary', 'warning', 'danger']),
};

Burger.defaultProps = {
  theme: 'primary',
};

export default Burger;
