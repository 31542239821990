// order
import rootDomain, {
  createStore,
  createEffect,
  createEvent,
  sample,
} from '@/store/rootDomain';
import { stringify } from 'querystring';

import { createForm } from 'effector-react-form';
import API, { postOrder } from '@/services/api';

export const orderSubmitFx = createEffect({
  handler: async ({ values }) =>
    API.post(postOrder(), stringify({ task: 'order', ...values })),
});

export const clear = createEvent();

export const $pending = orderSubmitFx.pending;

export const formOder = createForm({
  domain: rootDomain,
  onSubmit: orderSubmitFx,
});

sample({
  source: clear,
  target: formOder.reset,
});

export const $order = createStore({})
  .on(orderSubmitFx.doneData, (_, data) => ({
    ...data,
    isSubmitted: true,
  }))
  .on(orderSubmitFx.failData, (_, data) => ({
    ...data,
    isSubmitted: false,
  }))
  .reset(clear);
