import React from 'react';
import PhoneInput from 'react-phone-input-2';
import cx from 'clsx';
import * as styles from './input-phone.module.scss';

// type Props = {
//   className?: string;
//   classNameInput?: string;
//   bgColor?: string;
//   controller: Controller;
//   size?: 'sm' | 'md';
//   placeholder?: string;
// };

const InputPhone = ({
  className,
  classNameInput,
  controller,
  placeholder = '+7 (999) 999-99-99',
  bgColor = '#F5FAFE',
  size = 'md',
  vendorProps,
}) => {
  const {
    input: { value, ...restInput },
    isShowError,
    error,
  } = controller();

  return (
    <div
      className={cx('InputPhone', className, `size_${size}`, {
        error: isShowError,
      })}
      style={{ '--bg-color': bgColor }}
    >
      <div className={cx(styles.wrapInput, classNameInput)}>
        <PhoneInput
          {...restInput}
          value={value || ''}
          placeholder={placeholder}
          country="ru"
          {...vendorProps}
        />
      </div>

      {isShowError && (
        <div className={cx('c-input-error-message', styles.errorMessage)}>
          {error}
        </div>
      )}
    </div>
  );
};

export default React.memo(InputPhone);
