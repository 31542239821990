import React, { Fragment } from 'react';
import OrderModal from '@/containers/HomePage/OrderModal/OrderModal';
import { modals } from '@/constants/ctx';
import useModal from '@/hooks/useModal';

const Modals = () => {
  const { isOpen: isOpenOrderModal, close: closeOrderModal } = useModal(
    modals.orderModal
  );
  return (
    <Fragment>
      <OrderModal isOpen={isOpenOrderModal} close={closeOrderModal} />
    </Fragment>
  );
};

export default Modals;
