import React from 'react';
import {
  Button,
  Input,
  InputPhone,
  Textarea,
  // Error,
} from '@/components/inputs';
import cx from 'clsx';
import { validateRequired, validateRequiredEmail } from '@/utils/validation';
// import cx from 'clsx';
import * as styles from './OrderForm.module.scss';
import data from './data';

const isLastInOdd = (index) =>
  data.formElements.length % 2 === 1 && index === data.formElements.length - 1;

const OrderForm = ({ controller, handleSubmit, isPending }) => {
  return (
    <div className={styles.wrap}>
      <div className="d-flex ai-c">
        <h2
          className={styles.formTitle}
          dangerouslySetInnerHTML={{ __html: data.title }}
        />
      </div>
      <form className="mt-4" onSubmit={handleSubmit}>
        <div className="row">
          {data.formElements.map((formEl, index) => {
            let validate = formEl.required && validateRequired;
            let Component = Input;
            const isVendored = formEl.type === 'phone';

            if (formEl.type === 'email') {
              validate = validateRequiredEmail;
            }
            if (formEl.type === 'phone') {
              Component = InputPhone;
            }

            if (formEl.type === 'textarea') {
              Component = Textarea;
            }

            return (
              <div
                className={cx('col-12 mt-4', !isLastInOdd(index) && 'col-sm-6')}
                key={`order-form-el-${index}`}
              >
                <Component
                  label={`${formEl.label}${formEl.required ? '*' : ''}`}
                  controller={controller({
                    name: formEl.name,
                    validate,
                  })}
                  placeholder={formEl.placeholder}
                  vendorProps={isVendored && { ...formEl.props }}
                  {...(!isVendored ? formEl.props : {})}
                />
              </div>
            );
          })}
        </div>
        {/* <Error name="agree" /> */}
        <div className="mt-3 text-center">
          <Button
            className={styles.submitBtn}
            theme="secondary"
            type="submit"
            loading={isPending}
            disabled={isPending}
          >
            {data.submitText}
          </Button>
        </div>
      </form>
      <div
        className={styles.agreeText}
        dangerouslySetInnerHTML={{ __html: data.agreeText }}
      />
    </div>
  );
};

export default React.memo(OrderForm);
