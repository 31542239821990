// extracted by mini-css-extract-plugin
export const wrapInput = "input-module--wrapInput--OYlY3";
export const fieldset = "input-module--fieldset--1YwmI";
export const input = "input-module--input--nw3rH";
export const isVisibleControls = "input-module--isVisibleControls--3_NvA";
export const label = "input-module--label--10zUl";
export const legendElement = "input-module--legendElement--3cORp";
export const legendInnerElement = "input-module--legendInnerElement--3fiIN";
export const errorMessage = "input-module--errorMessage--20enF";
export const focus = "input-module--focus--3XEev";
export const active = "input-module--active--3ih12";
export const error = "input-module--error--1EY6l";
export const disabled = "input-module--disabled--1BRXR";
export const size_sm = "input-module--size_sm--3HZud";
export const size_md = "input-module--size_md--3k04z";
export const size_lg = "input-module--size_lg--2CVdj";
export const labelUpper = "input-module--labelUpper--ogSCl";