/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useRef } from 'react';
import cx from 'clsx';
import * as styles from './input.module.scss';

// export type Props = {
//   controller: Controller;
//   className?: string;
//   classNameInput?: string;
//   classNamePrefix?: string;
//   classNameSuffix?: string;
//   classNameFieldSet?: string;
//   label?: string;
//   autoComplete?: string;
//   size?: 'sm' | 'md' | 'lg';
//   placeholder?: string;
//   prefix?: React.ReactNode;
//   suffix?: React.ReactNode;
//   type?: 'text' | 'password' | 'number';
//   isVisibleControls?: boolean;
//   ref?: React.Ref<any>;
//   onKeyUp?: (e: SyntheticEvent) => void;
//   onBlur?: (e: SyntheticEvent) => void;
//   onChange?: (e: SyntheticEvent) => void;
//   disabled?: boolean;
// };

const Input = React.forwardRef(function Input(
  {
    controller,
    className,
    classNameInput,
    classNamePrefix,
    classNameSuffix,
    classNameFieldSet,
    label,
    autoComplete = 'off',
    size = 'md',
    placeholder,
    prefix,
    suffix,
    type = 'text',
    isVisibleControls,
    onKeyUp,
    onBlur,
    onChange,
    disabled,
  },
  ref
) {
  const {
    input,
    fieldState: { active },
    error,
    isShowError,
  } = controller();

  const refInput = useRef();

  React.useImperativeHandle(
    ref,
    () => ({
      focus: () => {
        refInput.current.focus();
      },
    }),
    []
  );

  const setFocus = () => {
    refInput.current.focus();
  };

  const innerOnBlur = useCallback(
    (e) => {
      if (onBlur) {
        onBlur(e);
      }

      input.onBlur(e);
    },
    [onBlur]
  );

  const innerOnChange = useCallback(
    (e) => {
      if (onChange) {
        onChange(e);
      }

      input.onChange(e);
    },
    [onChange]
  );

  return (
    <div className={cx(styles.wrap, className)}>
      <div
        className={cx(
          styles.wrapInput,
          {
            [styles.focus]: active,
            [styles.active]: active || input.value,
            [styles.error]: isShowError,
            [styles.disabled]: disabled,
            [styles.labelUpper]: Boolean(placeholder && label),
          },
          styles[`size_${size}`]
        )}
      >
        {prefix && (
          <span onClick={setFocus} className={classNamePrefix}>
            {prefix}
          </span>
        )}

        {label && (
          <span onClick={setFocus} className={styles.label}>
            {label}
          </span>
        )}

        <input
          {...input}
          onChange={innerOnChange}
          value={input.value || ''}
          autoComplete={autoComplete}
          type={type}
          ref={refInput}
          className={cx(classNameInput, styles.input, {
            [styles.isVisibleControls]: isVisibleControls,
          })}
          placeholder={placeholder}
          onKeyUp={onKeyUp}
          onBlur={innerOnBlur}
          disabled={disabled}
        />

        {suffix && (
          <span onClick={setFocus} className={classNameSuffix}>
            {suffix}
          </span>
        )}

        <fieldset
          aria-hidden
          className={cx(styles.fieldset, classNameFieldSet)}
        >
          <legend className={styles.legendElement}>
            {label && (
              <span className={styles.legendInnerElement}>{label}</span>
            )}
          </legend>
        </fieldset>
      </div>

      {isShowError && (
        <div className={cx('c-input-error-message', styles.errorMessage)}>
          {error}
        </div>
      )}
    </div>
  );
});

export default React.memo(Input);
