import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import * as styles from './navbar.module.scss';

const Navbar = ({ className, children, ...props }) => {
  return (
    <nav className={cx(styles.navbar, className)} {...props}>
      {children}
    </nav>
  );
};

Navbar.propTypes = {
  /** ClassName of the Navbar. Default class is navbar */
  className: PropTypes.string,

  /** Used to render menu, logo, button or any component that
   * you want to show in navbar. */
  children: PropTypes.element,
};

/** Navbar default proptype */
Navbar.defaultProps = {};

export default Navbar;
