/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useCallback } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import cx from 'clsx';
import * as styles from './textarea.module.scss';

// type Props = {
//   controller: Controller;
//   className?: string;
//   label?: string;
//   minRows?: number;
//   maxRows?: number;
//   transparent?: boolean;
// };

const Textarea = ({
  controller,
  className,
  label,
  transparent = false,
  minRows = 1,
  maxRows,
  activeByDefault = true,
}) => {
  const {
    input,
    isShowError,
    error,
    fieldState: { active },
  } = controller();

  const refTextarea = useRef();

  const setFocus = useCallback(() => {
    refTextarea.current.focus();
  }, []);

  return (
    <div className={cx(styles.wrap, className)}>
      <div
        className={cx(styles.wrapTextarea, {
          [styles.focus]: active,
          [styles.active]: active || input.value || activeByDefault,
          [styles.error]: isShowError,
          [styles.transparent]: transparent,
        })}
      >
        {label && (
          <span onClick={setFocus} className={styles.label}>
            {label}
          </span>
        )}

        <TextareaAutosize
          {...input}
          value={input.value || ''}
          ref={refTextarea}
          minRows={minRows}
          maxRows={maxRows}
          className={styles.textarea}
        />
        <fieldset aria-hidden className={styles.fieldset}>
          <legend className={styles.legendElement}>
            {label && (
              <span className={styles.legendInnerElement}>{label}</span>
            )}
          </legend>
        </fieldset>
      </div>

      {isShowError && (
        <div className={cx('c-input-error-message', styles.errorMessage)}>
          {error}
        </div>
      )}
    </div>
  );
};

export default React.memo(Textarea);
