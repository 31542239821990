import React from 'react';
import ReactResponsiveModal from 'react-responsive-modal';
import cx from 'clsx';
import * as styles from './Modal.module.scss';

const Modal = ({
  isOpen,
  center = false,
  onClose,
  children,
  classNameContent,
  classNameTitle,
  classNameModal,
  closeOnOverlayClick = true,
  fullWidth = false,
  title,
  focusTrapped = true,
}) => {
  const animationEnd = React.useCallback(() => {
    if (!isOpen) {
      document.documentElement.classList.remove('is-modal-opened');
      document.documentElement.classList.remove('is-active-padding-fix');
    }
  }, [isOpen]);

  return (
    <>
      <ReactResponsiveModal
        classNames={{
          closeIcon: styles.closeIcon,
          closeButton: styles.closeButton,
          modal: cx(styles.modal, classNameModal, { 'w-100': fullWidth }),
        }}
        open={isOpen}
        onClose={onClose}
        center={center}
        animationDuration={200}
        blockScroll={false}
        onAnimationEnd={animationEnd}
        closeOnOverlayClick={closeOnOverlayClick}
        focusTrapped={focusTrapped}
      >
        <div className={classNameContent}>
          {title && (
            <h2
              className={cx(
                'mt-n4 mb-2 fsz-28 pr-3',
                styles.title,
                classNameTitle
              )}
            >
              {title}
            </h2>
          )}
          {children}
        </div>
      </ReactResponsiveModal>
    </>
  );
};

export default React.memo(Modal);
