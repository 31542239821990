import React from 'react';
import cx from 'clsx';
import * as styles from './button.module.scss';

// Themes: primary, secondary
// Variants: gradient, solid, outline

// export type Theme =
//   | 'link'
//   | 'transparent'
//   | 'transparentHoverPrimary'
//   | 'gradient'
//   | 'primary'
//   | 'switch'
//   | 'switchActive';

// type Props = {
//   className?: string;
//   type?: 'button' | 'submit';
//   theme?: Theme;
//   onClick?: (e?: React.SyntheticEvent<HTMLButtonElement>) => void;
//   children?: React.ReactNode;
//   tag?: 'button' | 'a';
// };

const Button = ({
  onClick,
  type = 'button',
  theme = 'primary',
  variant = 'gradient',
  loading,
  disabled,
  children,
  className,
  tag = 'button',
  ...rest
}) => {
  const withBackground = variant === 'gradient';
  const isDefault = !withBackground;

  const content = (
    <>
      {withBackground && (
        <>
          <span className={styles.bgDefault} />
          <span className={styles.bgActive} />
          <span className={styles.content}>{children}</span>
        </>
      )}

      {isDefault && children}
    </>
  );

  const elProps = {
    onClick,
    className: cx(
      className,
      styles.button,
      styles[`is_variant_${variant}`],
      styles[`is_theme_${theme}`],
      loading && styles.is_loading,
      disabled && styles.is_disabled
    ),
    ...rest,
  };

  if (tag === 'button') {
    elProps.type = type;
  }

  return React.createElement(tag, elProps, content);
};

export default React.memo(Button);
