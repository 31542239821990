// extracted by mini-css-extract-plugin
export const wrap = "textarea-module--wrap--2A-No";
export const wrapTextarea = "textarea-module--wrapTextarea--1sMew";
export const fieldset = "textarea-module--fieldset---55aa";
export const transparent = "textarea-module--transparent--2Pc8a";
export const textarea = "textarea-module--textarea--3DEVw";
export const legendElement = "textarea-module--legendElement--25e5G";
export const legendInnerElement = "textarea-module--legendInnerElement--1NCmc";
export const label = "textarea-module--label--gtUbj";
export const errorMessage = "textarea-module--errorMessage--2ANxF";
export const focus = "textarea-module--focus--m2p5o";
export const active = "textarea-module--active--3jj39";
export const error = "textarea-module--error--22Lrl";