// extracted by mini-css-extract-plugin
export const button = "button-module--button--tnKJa";
export const content = "button-module--content--39ULy";
export const is_variant_gradient = "button-module--is_variant_gradient--BCPFM";
export const bgDefault = "button-module--bgDefault--1nKaw";
export const bgActive = "button-module--bgActive--1W4IP";
export const is_theme_primary = "button-module--is_theme_primary--2W9pY";
export const is_theme_secondary = "button-module--is_theme_secondary--1KLDd";
export const is_loading = "button-module--is_loading--BIr32";
export const cLoaderRotate = "button-module--c-loader-rotate--2Xzfg";
export const is_disabled = "button-module--is_disabled--1LbTn";