import {
  createDomain,
  attach,
  forward,
  combine,
  sample,
  step,
  restore,
  guard,
} from 'effector';

const rootDomain = createDomain();

// rootDomain.onCreateEvent((e: any) => {
//   const { create } = e;
//   e.create = (...args) => {
//     console.log('direct call of', e.shortName);
//     return create(...args);
//   };
// });
//
// rootDomain.onCreateEffect((e: any) => {
//   const { create } = e;
//   e.create = (...args) => {
//     console.log('direct call of', e.shortName);
//     return create(...args);
//   };
// });

// rootDomain.onCreateEvent((e) => {
//   // @ts-ignore
//   e.graphite.seq.unshift(
//     step.compute({
//       // @ts-ignore
//       fn(data, _, stack) {
//         // @ts-ignore
//         if (stack.node === e.graphite) {
//           console.trace(e.shortName, ' is called without scope');
//         }
//         return data;
//       },
//     })
//   );
// });

// rootDomain.onCreateEffect((e) => {
//   // @ts-ignore
//   e.graphite.seq.unshift(
//     // @ts-ignore
//     step.compute({
//       // @ts-ignore
//       fn(data, _, stack) {
//         // @ts-ignore
//         if (stack.node === e.graphite) {
//           console.trace(e.shortName, ' is called without scope');
//         }
//         return data;
//       },
//     })
//   );
// });

const { createStore, createEffect, createEvent } = rootDomain;

export default rootDomain;
export {
  createStore,
  createEffect,
  createEvent,
  attach,
  forward,
  combine,
  sample,
  restore,
  guard,
};
