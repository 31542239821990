const data = {
  title:
    'Записаться на личную <span class="text-nowrap">коуч-сессию</span> с Андреасом',
  formElements: [
    {
      type: 'text',
      label: 'Имя',
      name: 'name',
      placeholder: 'Алексей',
      required: true,
    },
    {
      type: 'text',
      label: 'Фамилия',
      name: 'surname',
      placeholder: 'Кочервеев',
      required: true,
    },
    {
      type: 'email',
      label: 'E-mail',
      name: 'email',
      placeholder: 'example@gmail.com',
      required: true,
    },
    {
      type: 'phone',
      label: 'Телефон',
      name: 'phone',
      placeholder: '+7 (9ХХ) ХХХ ХХ ХХ',
      required: true,
      props: {
        onlyCountries: ['ru', 'ua'],
        specialLabel: 'Телефон*',
      },
    },
    {
      type: 'textarea',
      label: 'Сообщение',
      name: 'message',
      placeholder: 'Текст сообщения',
      props: {
        minRows: 4,
      },
    },
  ],
  submitText: 'Отправить',
  agreeText:
    'Нажимая на данную кнопку вы соглашаетесь с <a class="text-underline" href="/docs/privacy-policy.pdf" target="_blank">политикой обработки персональных данных</a>',
  successTitle: 'Спасибо',
  successDesc: 'Ваша заявка успешно отправлена',
};

export default data;
