import LogoImg from '@/assets/img/logo.svg';

const data = {
  logoSrc: LogoImg,
  logoAlt: 'Андреас Тиссен',
  orderBtnText: 'Записаться',
  menuItems: [
    {
      label: 'Обо мне',
      path: '#about',
      offset: '100',
    },
    {
      label: 'Услуги',
      path: '#services',
      offset: '100',
    },
    {
      label: 'СМИ',
      path: '#news',
      offset: '80',
    },
    {
      label: 'Отзывы',
      path: '#reviews',
      offset: '100',
    },
    {
      label: 'Книги',
      path: '#books',
      offset: '100',
    },
    {
      label: 'Контакты',
      path: '#contacts',
      offset: '100',
    },
  ],
};
export default data;
