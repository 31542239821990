export const validateRequired = (value) =>
  value ? undefined : 'Обязательное поле';

export const validateEmail = (value) => {
  if (!value) {
    return undefined;
  }

  /* eslint-disable-next-line */
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(String(value).toLowerCase())) {
    return undefined;
  }

  return 'Неверный формат email';
};

export const validateRequiredEmail = (value) => {
  const required = validateRequired(value);
  const email = validateEmail(value);

  return required || email;
};
