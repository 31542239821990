import React, { useEffect, useRef, useCallback } from 'react';
import { useForm } from 'effector-react-form';
import { useStore, useEvent } from 'effector-react';
import Modal from '@/components/Modal/Modal';
import { formOder, $order, $pending, clear } from '@/store/order';
import OrderForm from '@/containers/HomePage/OrderForm/OrderForm';
import data from '@/containers/HomePage/OrderForm/data';
import * as styles from './OrderModal.module.scss';

const OrderModal = ({ isOpen, close }) => {
  const order = useStore($order);
  const isPending = useStore($pending);
  const isSubmitted = order.isSubmitted;
  const events = useEvent({
    clear,
  });

  const tIdRef = useRef();

  const {
    handleSubmit: handleSubmitOrder,
    controller: controllerOrder,
  } = useForm({ form: formOder });

  const handleClose = useCallback(() => {
    close();
    if (isSubmitted) {
      clearTimeout(tIdRef.current);
      setTimeout(() => {
        events.clear();
      }, 300);
    }
  }, [events, close, isSubmitted]);

  useEffect(() => {
    if (!isSubmitted) return;
    tIdRef.current = setTimeout(handleClose, 6000);
    return () => {
      clearTimeout(tIdRef.current);
    };
  }, [isSubmitted, handleClose]);

  return (
    <Modal
      classNameModal={styles.orderModal}
      classNameContent={styles.orderModalContent}
      isOpen={isOpen}
      onClose={handleClose}
      focusTrapped={false}
      center
    >
      {!isSubmitted ? (
        <OrderForm
          controller={controllerOrder}
          handleSubmit={handleSubmitOrder}
          isPending={isPending}
        />
      ) : (
        <div className={styles.success}>
          <h3
            className={styles.successTitle}
            dangerouslySetInnerHTML={{ __html: data.successTitle }}
          />
          <div
            className={styles.successDesc}
            dangerouslySetInnerHTML={{ __html: data.successDesc }}
          />
        </div>
      )}
    </Modal>
  );
};

export default OrderModal;
