import React, { Fragment, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Scrollspy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import cx from 'clsx';
import { motion, AnimateSharedLayout } from 'framer-motion';
import { Link } from 'gatsby';
import { DrawerContext } from '@/contexts/DrawerContext';
import * as styles from './ScrollSpyMenu.module.scss';

const ScrollSpyMenu = ({
  className,
  menuItems,
  isDrawerToggable,
  onUpdate = () => {},
  scrollLine,
  ...props
}) => {
  const { dispatch } = useContext(DrawerContext);
  const [activeId, setActiveId] = useState(null);
  // empty array for scrollspy items
  const scrollItems = [];

  // convert menu path to scrollspy items
  menuItems.forEach((item) => {
    scrollItems.push(item.path.slice(1));
  });

  // Close drawer when click on menu item
  const toggleDrawer = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  const handleUpdate = (activeDomEl) => {
    if (!activeDomEl && activeId) {
      setActiveId(null);
    } else if (activeDomEl) {
      setActiveId(activeDomEl.id);
    }
    onUpdate(activeDomEl);
  };

  return (
    <AnimateSharedLayout>
      <Scrollspy
        items={scrollItems}
        className={cx(styles.menu, className)}
        onUpdate={handleUpdate}
        {...props}
      >
        {menuItems.map((menuItem, index) => (
          <li key={`menu-item-${index}`}>
            {menuItem.staticLink ? (
              <Link to={menuItem.path}>{menuItem.label}</Link>
            ) : (
              <Fragment>
                {isDrawerToggable ? (
                  <AnchorLink
                    href={menuItem.path}
                    offset={menuItem.offset}
                    onClick={toggleDrawer}
                  >
                    {menuItem.label}
                  </AnchorLink>
                ) : (
                  <AnchorLink href={menuItem.path} offset={menuItem.offset}>
                    {menuItem.label}
                  </AnchorLink>
                )}
                {scrollLine && menuItem.path.slice(1) === activeId && (
                  <motion.div
                    layoutId="c-scroll-line"
                    className="c-scroll-line"
                  />
                )}
              </Fragment>
            )}
          </li>
        ))}
      </Scrollspy>
    </AnimateSharedLayout>
  );
};

ScrollSpyMenu.propTypes = {
  /** className of the ScrollSpyMenu. */
  className: PropTypes.string,

  /** menuItems is an array of object prop which contain your menu
   * data.
   */
  menuItems: PropTypes.array.isRequired,

  isDrawerToggable: PropTypes.bool,

  /** Class name that apply to the navigation element paired with the content element in viewport. */
  currentClassName: PropTypes.string,

  /** Class name that apply to the navigation elements that have been scrolled past [optional]. */
  scrolledPastClassName: PropTypes.string,

  /** HTML tag for Scrollspy component if you want to use other than <ul/> [optional]. */
  componentTag: PropTypes.string,

  /** Style attribute to be passed to the generated <ul/> element [optional]. */
  style: PropTypes.object,

  /** Offset value that adjusts to determine the elements are in the viewport [optional]. */
  offset: PropTypes.number,

  /** Name of the element of scrollable container that can be used with querySelector [optional]. */
  rootEl: PropTypes.string,

  /**
   * Function to be executed when the active item has been updated [optional].
   */
  onUpdate: PropTypes.func,
};

ScrollSpyMenu.defaultProps = {
  componentTag: 'ul',
  currentClassName: 'is-current',
};

export default ScrollSpyMenu;
